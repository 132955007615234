// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";


@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles';

.btn-primary, .btn-secondary {
  color: white;
}

.ant-spin-dot-item {
  background-color:  $primary-base;
}

.ant-picker-dropdown {
  z-index: 9999!important;
}

.ant-picker-dropdown {
  z-index: 9999!important;
}

.ant-modal-root {
  z-index: 9999!important;
}

.rbc-month-row {
  display: inline-table !important;
  flex: 0 0 0 !important;
  min-height: 180px !important;
}

